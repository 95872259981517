import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './footer.module.scss';

const Footer = () => {
  const { t } = useTranslation();

  // const year = new Date().getFullYear();

  return (
    <div className={styles.footer}>
      <div className={styles.footer_mobile}>
        <p className={styles.footer_text}>
          <a
            className={styles.footer_text__link}
            href="https://app.paytip.pl/documents/Regulamin_PayTip_-_15.06.2020.pdf"
          >
            {t('terms')}
          </a>
        </p>
        <p className={styles.footer_text}>
          <a
            className={styles.footer_text__link}
            href="https://app.paytip.pl/documents/Polityka_prywatnosci_-_15.06.2020.pdf"
          >
            {t('privacy_policy')}
          </a>
        </p>
        <p className={styles.footer_text}>
          <a
            className={styles.footer_text__link}
            href="https://app.paytip.pl/documents/Regulamin_promocji_-_Na_dobry_start_-_15.06.2020.pdf"
          >
            {t('promo_terms')}
          </a>
        </p>
        <p className={styles.footer_text}>
          {t('knf_number')}
        </p>
        <p className={styles.footer_text}>
          {t('address_part1')}
        </p>
        <p className={styles.footer_text}>
          {t('address_part2')}
        </p>
        <p className={styles.footer_text}>
          © 2019 - {t('all_rights_reserved')}
        </p>
      </div>
      {/* Desktop view only */}
      <div className={styles.footer_desktop}>
        <div className={styles.footer_desktop__links}>
          <a
            className={styles.footer_text__link}
            href="https://app.paytip.pl/documents/Regulamin_PayTip_-_15.06.2020.pdf"
          >
            {t('terms')}
          </a>
          <a
            className={styles.footer_text__link}
            href="https://app.paytip.pl/documents/Polityka_prywatnosci_-_15.06.2020.pdf"
          >
            {t('privacy_policy')}
          </a>
          <a
            className={styles.footer_text__link}
            href="https://app.paytip.pl/documents/Regulamin_promocji_-_Na_dobry_start_-_15.06.2020.pdf"
          >
            {t('promo_terms')}
          </a>
        </div>
        <p className={styles.footer_text}>
          {t('knf_number')}
        </p>
        <div>
          <p className={styles.footer_text}>
            {t('address_part1')} {t('address_part2')}
          </p>
          <p className={styles.footer_text}>
            © 2019 - {t('all_rights_reserved')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
