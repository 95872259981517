import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { scroller, Link as ReactScroll } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import payTipLogo from '../../images/tipcode_give.svg';
import giverLogo from '../../images/giver_logo.svg';
import styles from './header.module.scss';

const Header = () => {
  const { t } = useTranslation();
  const menuRef = useRef();
  const menuListRef = useRef();

  const scrollTime = 500;

  const handleMenuClick = () => {
    menuRef.current.classList.toggle(styles.header_menu__isOpen);
  };

  // Add hash to site url when scroll animation is complete
  const handleMenuItemClick = (event) => {
    event.preventDefault();
    const linkHash = event.target.href.replace(/^.*?(#|$)/,'');
    // Remove active link class from all menu items
    menuListRef.current.childNodes.forEach((node) => {
      node.firstChild.classList.remove(styles.header_menuItems__linkActive);
    });
    // Add active link class to target element
    event.target.classList.add(styles.header_menuItems__linkActive);
    menuRef.current.classList.toggle(styles.header_menu__isOpen);
    setTimeout(() => {
      window.location.hash = linkHash;
    }, scrollTime + 100);
  };

  // When enter page with hash in url try to scroll page to given position
  useEffect(() => {
    if (window.location.hash) {
      menuListRef.current.childNodes.forEach((node) => {
        if (window.location.hash === '#' + node.firstChild.href.split('#')[1]) {
          node.firstChild.classList.add(styles.header_menuItems__linkActive);
        }
      });
      setTimeout(() => {
        scroller.scrollTo(window.location.hash.replace('#', ''), {
          duration: scrollTime,
          smooth: true
        });
      }, scrollTime);
    }
  }, []);

  return (
    <header className={styles.header}>
      <h1 className={styles.header_mainHeader}>
        <a
          href="/"
          className={styles.header_siteTitle}
        >
          {t('application_name')}
        </a>
      </h1>
      <div className={styles.header_menuMobile}>
        <div>menu</div>
        <div
          className={styles.header_menuMobile__button}
          onClick={handleMenuClick}
        >
          <div className={styles.header_menuMobile__line} />
          <div className={styles.header_menuMobile__line} />
          <div className={styles.header_menuMobile__line} />
        </div>
      </div>
      <nav
        className={styles.header_menu}
        ref={menuRef}
      >
        <div
          className={styles.header_menuClose}
          onClick={handleMenuClick}
        >
          <div className={`${styles.header_menuCloseLine} ${styles.header_menuCloseLine__horizontal}`} />
          <div className={styles.header_menuCloseLine} />
        </div>
        <ul
          ref={menuListRef}
          className={styles.header_menuItems}
        >
          <li className={`${styles.header_menuItems__item} ${styles.header_menuItems__mobileItem}`}>
            <a
              className={styles.header_menuItems__link}
              href="https://giver.paytip.pl/"
            >
              <img
                className={styles.header_menuItems__mobileImage}
                src={giverLogo}
                alt="Giver Logo"
                height="40"
              />
              <div>
                {t('give_tip')}
              </div>
            </a>
          </li>
          <li className={`${styles.header_menuItems__item} ${styles.header_menuItems__mobileItem}`}>
            <a
              className={styles.header_menuItems__link}
              href="https://app.paytip.pl/"
            >
              <img
                className={styles.header_menuItems__mobileImage}
                src={payTipLogo}
                alt="PayTip Logo"
                height="40"
              />
              <div>
                {t('receive_tip_form')}
              </div>
            </a>
          </li>
          <li className={styles.header_menuItems__item}>
            <ReactScroll
              className={styles.header_menuItems__link}
              href="#ForWho"
              to="ForWho"
              smooth={true}
              duration={scrollTime}
              onClick={handleMenuItemClick}
            >
              {t('for_who')}
            </ReactScroll>
          </li>
          <li className={styles.header_menuItems__item}>
            <ReactScroll
              className={styles.header_menuItems__link}
              href="#WhyWorth"
              to="WhyWorth"
              smooth={true}
              duration={scrollTime}
              onClick={handleMenuItemClick}
            >
              {t('why_worth')}
            </ReactScroll>
          </li>
          <li className={styles.header_menuItems__item}>
            <ReactScroll
              className={styles.header_menuItems__link}
              href="#HowWorks"
              to="HowWorks"
              smooth={true}
              duration={scrollTime}
              onClick={handleMenuItemClick}
            >
              {t('how_works')}
            </ReactScroll>
          </li>
          <li className={styles.header_menuItems__item}>
            <ReactScroll
              className={styles.header_menuItems__link}
              href="#Safety"
              to="Safety"
              smooth={true}
              duration={scrollTime}
              onClick={handleMenuItemClick}
            >
              {t('safety')}
            </ReactScroll>
          </li>
          <li className={styles.header_menuItems__item}>
            <ReactScroll
              className={styles.header_menuItems__link}
              href="#Opinions"
              to="Opinions"
              smooth={true}
              duration={scrollTime}
              onClick={handleMenuItemClick}
            >
              {t('opinions')}
            </ReactScroll>
          </li>
        </ul>
      </nav>
      <div className={styles.header_empty} />
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
